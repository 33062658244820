import { GET_ASSEMBLY_PROJECTS, CREATE_ASSEMBLY_PROJECT, ARCHIVE_ASSEMBLY_PROJECT } from '../../queries';
import Link from 'next/link';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useMutation } from '@apollo/client';
import { Container, Row, UncontrolledAlert, Table, Col, Button, Alert } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import React, { Fragment, useContext, useEffect, useState, useMemo, useRef } from 'react';
import Navigation from '../Navigation/Navigation';
import Sidebar from '../Sidebar/Sidebar';
import moment from 'moment';
import { useRouter } from 'next/router';
import { progressBarStore } from '../../context/ProgressBarContext';
import Progress from '../Progress';
import ModalConfirmfrom from '../Modal/ModalConfirm';

enum DashboardFilter {
  all = 'All',
  owner = 'Created by me',
  shareWithMe = 'Shared with me',
  // archive = 'Archive', // ---temporarily hide---
}

declare type DashboardFilterType = keyof typeof DashboardFilter;

type UndoAlertProps = {
  assemblyProject?: AssemblyProject | null;
  pushProgress: (arg0: any) => void;
  archiveAssemblyProject: (arg0: any) => void;
}
const UndoAlert = ({assemblyProject, pushProgress, archiveAssemblyProject}: UndoAlertProps) => {
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);

  useEffect(() => {
    setVisible(!!(assemblyProject?.archived));
  }, [assemblyProject])

  return (
    <Alert color="info" isOpen={visible} toggle={onDismiss}>
      {assemblyProject && `Project "${assemblyProject?.name}" archived`}
      {!assemblyProject && "Loading..."}
      <Button
        color="link"
        onClick={() => {
          onDismiss();
          pushProgress('dashboard');
          archiveAssemblyProject({
            variables: {
              projectId: assemblyProject?.id,
              archive: false,
            },
          });
        }}
      >
        Undo
      </Button>
    </Alert>
  );
};

const Dashboard = () => {
  const router = useRouter();
  const { pushProgress, completeProgress } = useContext(progressBarStore);
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>(DashboardFilter.all);
  const { isLoading: isAuthenticating, error: authError, isAuthenticated } = useAuth0();
  const [isOpenModalConfirmDeleteMedia, setIsOpenModalConfirmDeleteMedia] = useState(false);
  const removeProjectMessage = useRef("");
  const [showUndoAlert, setShowUndoAlert] = useState(false);

  const {
    loading: loadingAssemblyProjects,
    data: assemblyProjectsData,
    error: errorLoadingAssemblyProjects,
    refetch: refetchAssemblyProjects
  } = useQuery<AssemblyProjectsData, never>(GET_ASSEMBLY_PROJECTS, {
    onCompleted: () => {
      completeProgress('dashboard');
    },
  });

  const [
    createNewAssemblyProject,
    { data: newProject, loading: loadingNewProject, error: errorNewProject },
  ] = useMutation<{
    createNewAssemblyProject: AssemblyProject;
    name: string;
  }>(CREATE_ASSEMBLY_PROJECT, {
    variables: { name: 'New Video Edit' },
    onCompleted: data => {
      router.push(`/${data.createNewAssemblyProject.id}`);
      refetchAssemblyProjects();
    },
  });

  const [archiveAssemblyProject, { data: archivedProject, loading: loadingArchiveAssemblyProject }] = useMutation<{
    archiveAssemblyProject: AssemblyProject;
    projectId: string;
    archive: boolean;
  }>(ARCHIVE_ASSEMBLY_PROJECT, {
    onCompleted: data => {
      completeProgress('dashboard');
    },
  });

  useEffect(() => {
    if (isAuthenticating) {
      pushProgress('authenticating');
    } else {
      // setting this in the future to ensure we don't encounter race condition when isAuthenticating changes from false to true before pushProgress has completed
      setTimeout(() => completeProgress('authenticating'), 100);
    }
  }, [isAuthenticating]);

  useEffect(() => {
    return () => {
      completeProgress('dashboard');
      completeProgress('authenticating');
      completeProgress('projectCreate');
    };
  }, []);

  useEffect(() => {
    if (loadingAssemblyProjects) pushProgress('dashboard');
  }, [loadingAssemblyProjects]);

  const handleCreateProjectClicked = () => {
    createNewAssemblyProject();
    pushProgress('projectCreate');
  };

  // let progressPercent = (!isAuthenticating ? 50: 0) + (assemblyProjectsData ? 50 : 0);
  // console.log({ isAuthenticating, assemblyProjectsData});
  // let progressUpTo = progressPercent + 45;
  // if (loadingNewProject || newProject) {
  //   progressPercent = 0;
  //   progressUpTo = 95;
  // }

  let title = 'Dashboard';
  let errorMessage = '';
  if (errorLoadingAssemblyProjects) {
    errorMessage = "😓 Uh oh. Couldn't load your projects. Check your internet settings and try reloading";
  }
  if (errorNewProject) {
    errorMessage = "😓 Uh oh. Couldn't create a new project. Check your internet settings and try again";
  }
  if (authError) {
    errorMessage = "😓 Uh oh. Couldn't load your account. Check your internet settings and try reloading";
  }

  if (isAuthenticating) {
    title = 'Logging you in...';
  } else if (loadingAssemblyProjects) {
    title = 'Loading your projects...';
  }

  const renderProjectList = useMemo(() => {
    if (!assemblyProjectsData) return null;

    function setRemoveProjectMessage(project: any) {
      if(!project.is_owner) {
        removeProjectMessage.current = "Are you sure you want to remove your access to this project? This action cannot be undone.";

      } else if (project.is_owner && project.durationInSeconds > 0) {
        removeProjectMessage.current = "Are you sure you want to delete this project? All selections in your timeline will be deleted. " + 
                                      "Any users you have shared this project with will no longer be able to access this project. This action cannot be undone";
      } else {
        removeProjectMessage.current = "";
      }
      
      if(removeProjectMessage.current) {
        setIsOpenModalConfirmDeleteMedia(true)
      }
    }

    function hideOrArchiveProject(project: any) {
      pushProgress('dashboard');
      archiveAssemblyProject({variables: { projectId: project.id, archive: true }})
    }

    return assemblyProjectsData.getAssemblyProjects
      .filter((project: AssemblyProject) => {
        if (dashboardFilter === DashboardFilter.all) return !project.archived;
        else if (dashboardFilter === DashboardFilter.shareWithMe) return !project.is_owner && !project.archived;
        else if (dashboardFilter === DashboardFilter.owner) return project.is_owner && !project.archived;
        // else if (dashboardFilter === DashboardFilter.archive) return project.archived; // ---temporarily hide---
      })
      .map((project: any) => {
        let owner = <span>Anonymous user</span>;
        let title = project.owner?.full_name || '';
        const img = project.owner?.avatar && (
          <img
            src={project.owner?.avatar}
            alt={project.owner?.full_name || project.owner?.email}
            className="user-picture"
          />
        );

        if (project.owner?.email) {
          title += ` <${project.owner?.email}>`;
        }

        if (project.is_owner) {
          owner = <span title={title.trim()}>{img} You</span>;
        } else if (project.owner) {
          owner = (
            <span title={title.trim()}>
              {img} {project.owner?.full_name || project.owner?.email}
            </span>
          );
        }

        const TrashIconContext = ({ project }: any) => {
          if (true) { //if(dashboardFilter !== DashboardFilter.archive) { // ---temporarily hide---
            return (
              <span onClick={() => hideOrArchiveProject(project)} title={project.is_owner ? "archive this project" : "hide this shared project from my dashboard"} style={{cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faTrashAlt} width={16} height={16} />
              </span>
            )
          }

          return (
            <span onClick={() => setRemoveProjectMessage(project)} title={project.is_owner ? "delete project" : "remove my access"} style={{cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faTrashAlt} width={16} height={16} />
            </span>
          )
        }
        
        return (
          <tr className="project-info" key={project.id}>
            <td>
              <div className="project-name">
                <div className="project-name__thumb">
                  <Link
                    href={{
                      pathname: `/[projectid]`,
                      query: {
                        projectid: project.id,
                        // ...(
                        //   project?.imported_media?.length &&
                        //   { m1: project.imported_media[0].id }
                        //   || {}
                        // )
                      }
                    }}
                  >
                    <a>
                      <img src={project.thumb || process.env.NEXT_PUBLIC_DEFAULT_THUMB} alt={project?.name} />
                    </a>
                  </Link>
                </div>
                <div className="project-name__text">
                  <Link
                    href={{
                      pathname: `/[projectid]`,
                      query: {
                        projectid: project.id,
                        // ...(
                        //   project?.imported_media?.length &&
                        //   { m1: project.imported_media[0].id }
                        //   || {}
                        // )
                      }
                    }}
                  >
                    {project.name}
                  </Link>
                </div>
              </div>
            </td>
            <td className="project-duration">
              {moment.duration(String(project.durationInSeconds), 'seconds').format('h[h]m[m]s[s]')}
            </td>
            <td className="project-latest-edit">
              {moment.unix(Number(project.last_modification_date) / 1000).fromNow()}
            </td>
            <td className="project-created">{moment.unix(Number(project.creation_date) / 1000).fromNow()}</td>
            <td className="project-owner">{owner}</td>
            <td className="project-tools">
              <TrashIconContext project={project} />
            </td>
          </tr>
        );
      });
  }, [assemblyProjectsData, dashboardFilter]);

  const renderDashboard = useMemo(() => {
    if (errorMessage) return null;

    return (
      <Row style={{ justifyContent: 'center' }} noGutters>
        <Col md="8" xs="11">
          <div className="mt-3 text-right">
            <UncontrolledDropdown>
              <DropdownToggle caret className="btn btn-simon">
                Create
              </DropdownToggle>
              {isAuthenticated && (
                <DropdownMenu>
                  <DropdownItem onClick={handleCreateProjectClicked} disabled={loadingNewProject || !!newProject}>
                    Create Project
                  </DropdownItem>
                  {/* <DropdownItem disabled>Create Folder</DropdownItem> */}
                </DropdownMenu>
              )}
              {!isAuthenticated && (
                <DropdownMenu>
                  <DropdownItem disabled>Login to create new projects</DropdownItem>
                </DropdownMenu>
              )}
            </UncontrolledDropdown>
          </div>
          <div className="mt-3 p-2 dashboard-box">
            <div className="dashboard-box__header">
              <div>
                <UncontrolledDropdown className="dashboard-filter">
                  <DropdownToggle className="dashboard-filter__button" caret>
                    {dashboardFilter}
                  </DropdownToggle>
                  {isAuthenticated && (
                    <DropdownMenu>
                      {
                        Object.keys(DashboardFilter).map((filterKey: string, index) => {
                          const currentFilter = DashboardFilter[filterKey as DashboardFilterType];

                          return (
                            <DropdownItem
                              key={'dashboard-filter-' + index}
                              className={`dashboard-filter__item ${dashboardFilter === currentFilter && 'active'}`}
                              onClick={() => setDashboardFilter(currentFilter)}
                            >
                              { currentFilter }
                            </DropdownItem>
                          )
                        })
                      }
                    </DropdownMenu>
                  )}
                </UncontrolledDropdown>
              </div>
            </div>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Duration</th>
                  <th>Last edited</th>
                  <th>Created</th>
                  <th>Owner</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(loadingNewProject || newProject) && (
                  <tr key="new">
                    <td>Creating project...</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                {renderProjectList}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    );
  }, [errorMessage, isAuthenticated, loadingNewProject, newProject, renderProjectList, handleCreateProjectClicked]);

  function handleRemoveProject() {

  }

  return (
    <Fragment>
      <Progress />
      {errorMessage.length > 0 && <UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert>}
      <UndoAlert assemblyProject={archivedProject?.archiveAssemblyProject} pushProgress={pushProgress} archiveAssemblyProject={archiveAssemblyProject} />
      <div className="wrapper">
        <Sidebar showLoaded={false} showImport={false} />
        <Navigation name={title} />
        <Container fluid id="canvas">
          {renderDashboard}
        </Container>
      </div>
      <ModalConfirmfrom
        isOpen={isOpenModalConfirmDeleteMedia}
        toggle={() => setIsOpenModalConfirmDeleteMedia(!isOpenModalConfirmDeleteMedia)}
        cancel={() => setIsOpenModalConfirmDeleteMedia(false)}
        confirm={() => handleRemoveProject()}
        title="Delete Project"
        message={removeProjectMessage.current}
      />
    </Fragment>
  );
};
export default Dashboard;
